import React, { useEffect } from "react";
import { motion } from "framer-motion"; // Importa framer-motion
import "./style.css";
import { useStore } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";

export default function Profile() {
  const { getUserProfile, user } = useStore();
  const [servicesPerPage, setServicesPerPage] = React.useState(5);
  const [userProfile, setUser] = React.useState(null);
  const [index, setIndex] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSlice = (dir) => {
    if (dir) {
      if (index + servicesPerPage >= userProfile.Services.length) {
        return;
      }
      setIndex(index + servicesPerPage);
    } else {
      if (index - servicesPerPage < 0) {
        return;
      }
      setIndex(index - servicesPerPage);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const data = await getUserProfile(location.pathname.split("/")[2]);
      setUser(data);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const updateServicesPerPage = () => {
      const width = window.innerWidth;
      if (width < 576) {
        setServicesPerPage(1);
      } else if (width < 768) {
        setServicesPerPage(2);
      } else if (width < 992) {
        setServicesPerPage(3);
      } else {
        setServicesPerPage(5);
      }
    };

    window.addEventListener("resize", updateServicesPerPage);
    updateServicesPerPage();

    return () => {
      window.removeEventListener("resize", updateServicesPerPage);
    };
  }, []);

  return (
    <>
      {userProfile ? (
        <motion.div
          className="container"
          initial={{ opacity: 0 }} // Estado inicial
          animate={{ opacity: 1 }} // Estado animado
          exit={{ opacity: 0 }} // Estado de salida
          transition={{ duration: 0.5 }} // Duración de la transición
        >
          <div className="profile-container">
            <div className="profile-header">
              <h1>Perfil de Usuario</h1>
              {user.id === Number(location.pathname.split("/")[2]) && (
                <button
                  className="edit-profile-btn"
                  onClick={() => navigate(`/edit-profile`)}
                >
                  Editar Perfil
                </button>
              )}
            </div>
            <div className="profile-content">
              <div className="profile-sidebar">
                <img
                  className="profile-pic"
                  src={
                    userProfile.photo
                      ? `https://www.contrataexpertos.com.ar/Perfil/Usuarios/${userProfile.photo}`
                      : "https://www.contrataexpertos.com.ar/Perfil/Usuarios/edit-profile-vector-icon.jpg"
                  }
                  alt="User"
                />
                <h2>
                  {userProfile.firstName} {userProfile.lastName}
                </h2>
                <p style={{ textAlign: "start" }}>{userProfile.description}</p>
              </div>
              <div className="profile-main">
                <motion.div
                  className="profile-section"
                  initial={{ opacity: 0, y: 20 }} // Estado inicial
                  animate={{ opacity: 1, y: 0 }} // Estado animado
                  exit={{ opacity: 0, y: -20 }} // Estado de salida
                  transition={{ duration: 0.3 }} // Duración de la transición
                >
                  <h3>Servicios Publicados</h3>
                  <div className="services-list">
                    {userProfile.Services.length > 0 ? (
                      <>
                        <button
                          className="btn-serv"
                          onClick={() => handleSlice(false)}
                          disabled={index - 3 < 0}
                        >
                          Prev
                        </button>
                        {userProfile.Services.slice(index, index + 3).map(
                          (service, idx) => (
                            <div className="service-card" key={idx}>
                              <div
                                style={{
                                  width: "10em",
                                  height: "3em",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  lineHeight: "1.5em",
                                }}
                              >
                                <strong
                                  onClick={() =>
                                    navigate(`/service/${service.id}`)
                                  }
                                >
                                  {service.title}
                                </strong>
                              </div>
                              <img
                                src={`https://www.contrataexpertos.com.ar/Servicios/Imagenes/${service.Images[0]?.url}`}
                                alt="Imagen del servicio"
                              />
                            </div>
                          )
                        )}
                        <button
                          className="btn-serv"
                          onClick={() => handleSlice(true)}
                          disabled={index + 3 >= userProfile.Services.length}
                        >
                          Next
                        </button>
                      </>
                    ) : (
                      <p>Aun no tiene servicios cargados</p>
                    )}
                  </div>
                </motion.div>
                <motion.div
                  className="profile-section"
                  initial={{ opacity: 0, y: 20 }} // Estado inicial
                  animate={{ opacity: 1, y: 0 }} // Estado animado
                  exit={{ opacity: 0, y: -20 }} // Estado de salida
                  transition={{ duration: 0.3 }} // Duración de la transición
                >
                  <h3>Recomendaciones</h3>
                  <div className="recommendations">
                    <p>
                      Comentario de un cliente satisfecho sobre el servicio
                      proporcionado por el usuario.
                    </p>
                    <p className="client">- Cliente 1</p>
                    <p>
                      Otro comentario positivo de un cliente contento con el
                      servicio.
                    </p>
                    <p className="client">- Cliente 2</p>
                  </div>
                </motion.div>
                <motion.div
                  className="profile-section"
                  initial={{ opacity: 0, y: 20 }} // Estado inicial
                  animate={{ opacity: 1, y: 0 }} // Estado animado
                  exit={{ opacity: 0, y: -20 }} // Estado de salida
                  transition={{ duration: 0.3 }} // Duración de la transición
                >
                  <h3>Rating</h3>
                  <div className="rating">
                    <span>⭐⭐⭐⭐☆</span>
                    <p>
                      Promedio de calificaciones basado en las opiniones de los
                      clientes.
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
      ) : (
        // Mensaje de carga o error
        <motion.div
          className="loading"
          initial={{ opacity: 0 }} // Estado inicial
          animate={{ opacity: 1 }} // Estado animado
          exit={{ opacity: 0 }} // Estado de salida
          transition={{ duration: 0.5 }} // Duración de la transición
        >
          <h1>Cargando perfil...</h1>
        </motion.div>
      )}
    </>
  );
}
