// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_button__HRfp0{

    background-color: transparent;
    border:none;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/style.module.css"],"names":[],"mappings":"AAAA;;IAEI,6BAA6B;IAC7B,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".button{\n\n    background-color: transparent;\n    border:none;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `style_button__HRfp0`
};
export default ___CSS_LOADER_EXPORT___;
