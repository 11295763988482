// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: hsl(0, 0%, 96%);
}
.custom-bg-color {
  background-color: #e2e6f5; 
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/LoginScreen.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;AACA;EACE,yBAAyB;AAC3B","sourcesContent":["body {\n  background-color: hsl(0, 0%, 96%);\n}\n.custom-bg-color {\n  background-color: #e2e6f5; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
