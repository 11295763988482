import axios from 'axios';
import {URL} from '../../http';

const userServiceGet = async () => {
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${URL.URL_API}/api/user/service`,
    {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    console.log("result",data)
    
    return data;
}


const userServiceAction = {
    userServiceGet
};

export default userServiceAction;