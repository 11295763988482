import React, { useState } from "react";
import axios from "axios";
import "./styles.css"; // Importa los estilos desde el archivo separado

function RequestPasswordReset({ onNextStep }) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:4041/api/auth/verificar-correo",
        { email }
      );
      setMessage(response.data.message);
      onNextStep(email); // Avanza a la siguiente ventana con el email
    } catch (error) {
      setMessage("Hubo un problema. Verifica el email ingresado.");
    }
  };

  return (
    <div className="container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center',height: '100vh',}}>
      <h2 className="title">Recuperar Contraseña</h2>
      <form onSubmit={handleSubmit} className="form">
        <label className="label">
          Ingresa tu correo electrónico:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input"
          />
        </label>
        <button type="submit" className="button">
          Enviar Código
        </button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
}

export default RequestPasswordReset;
