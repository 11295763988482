// Skeleton.js
import React from "react";
import { motion } from "framer-motion";
import "./Skeleton.css"; // Puedes agregar estilos para el esqueleto aquí

const Skeleton = ({ width, height }) => {
  return (
    <motion.div
      className="skeleton"
      style={{ width, height }}
      initial={{ opacity: 0.5, scale: 0.95 }} // Efecto inicial
      animate={{ opacity: 1, scale: 1 }} // Animación de carga
      exit={{ opacity: 0.5, scale: 0.95 }} // Estado de salida
      transition={{ duration: 0.3 }} // Duración de la transición
    />
  );
};

export default Skeleton;