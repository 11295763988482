import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css"; // Importa los estilos desde el archivo separado

function ChangePassword({ email }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const isPasswordValid = (password) => {
    return password.length >= 8 && /\d/.test(password) && /[a-zA-Z]/.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Las contraseñas no coinciden.");
      return;
    }

    if (!isPasswordValid(password)) {
      setMessage(
        "La contraseña debe tener al menos 8 caracteres, incluyendo letras y números."
      );
      return;
    }

    try {
      await axios.post("http://localhost:4041/api/auth/change-password", {
        email,
        password,
      });
      alert("Tu contraseña ha sido cambiada con éxito.");
      navigate("/login");
    } catch (error) {
      setMessage("Hubo un problema al cambiar la contraseña.");
    }
  };

  return (
    <div className="container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center',height: '100vh',}}>
      <h2 className="title">Cambiar Contraseña</h2>
      <form onSubmit={handleSubmit} className="form">
        <label className="label">
          Nueva Contraseña:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="input"
          />
        </label>
        <label className="label">
          Confirmar Contraseña:
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="input"
          />
        </label>
        <button type="submit" className="button">
          Cambiar Contraseña
        </button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
}

export default ChangePassword;
