import { useState, useEffect } from 'react';
import { useStore } from '../store';

const useIsFavorite = (serviceId) => {
  const { user } = useStore();
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (user && user.Favorites) {
        setIsFavorite([user.Favorites].includes(serviceId));
    }
  }, [user, serviceId]);

  return isFavorite;
};

export default useIsFavorite;