import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

//Style
import style from "./style.module.css";
import { useStore } from "../../store";
import useNavigationChange from "../../hook/navigation";

const Sidebar = () => {
  const { handleModal, showSidebar, authLogout, user } = useStore();
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  const handleNavigate = (route) => {
    handleModal(!showSidebar);
    navigate(route, { state: { userDueño: true } });
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      handleModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <div ref={sidebarRef} className={style.sidebar}>
      <p onClick={() => handleNavigate("/")}>Inicio</p>
      <p onClick={() => handleNavigate("/favoritos")}>Mis favoritos</p>
      <p onClick={() => handleNavigate("/sitioembebido")}>Seguro Accidentes Personales</p>
      {user?.idRol === "2" && (
        <p onClick={() => handleNavigate("/admin")}>Admin</p>
      )}
      <p onClick={() => handleNavigate("/ABM_Servicios/MisServicios")}>
        Mis servicios
      </p>
      <p onClick={() => handleNavigate("/ABM_Servicios/NuevoServicio")}>
        Crear servicios
      </p>
      <p onClick={() => handleNavigate(`/profile/${user.id}`)}>Mi perfil</p>
      <p onClick={() => authLogout(navigate)}>Salir</p>
    </div>
  );
};

export default Sidebar;
