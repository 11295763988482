// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_sidebar__MiCA7 {
  position: fixed;
  border: 1px solid red;
  right: 0;
  top: 5em;
  
  margin-right: 10px;
  background: rgba( 255, 255, 255 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  width: 25%;
  padding: 10px;
  z-index: 1000;
}

.style_sidebar__MiCA7 > p:hover{
  font-weight: bold;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/style.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,qBAAqB;EACrB,QAAQ;EACR,QAAQ;;EAER,kBAAkB;EAClB,iCAAiC;EACjC,kDAAkD;EAClD,mBAAmB;EACnB,6CAA6C;EAC7C,UAAU;EACV,aAAa;EACb,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".sidebar {\n  position: fixed;\n  border: 1px solid red;\n  right: 0;\n  top: 5em;\n  \n  margin-right: 10px;\n  background: rgba( 255, 255, 255 );\n  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );\n  border-radius: 10px;\n  border: 1px solid rgba( 255, 255, 255, 0.18 );\n  width: 25%;\n  padding: 10px;\n  z-index: 1000;\n}\n\n.sidebar > p:hover{\n  font-weight: bold;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `style_sidebar__MiCA7`
};
export default ___CSS_LOADER_EXPORT___;
