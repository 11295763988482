import React, { useState } from "react";
import RequestPasswordReset from "./RequestPasswordReset";
import ChangePassword from "./ChangePassword";
import VerificationCode from "../Verification/VerificationCode";

function PasswordResetFlow() {
  const [currentStep, setCurrentStep] = useState(1); // Controla qué ventana se muestra
  const [email, setEmail] = useState(""); // Guarda el email del usuario

  // Función para avanzar al siguiente paso
  const handleNextStep = (userEmail) => {
    if (userEmail) setEmail(userEmail); // Guarda el email solo en el primer paso
    setCurrentStep((prevStep) => prevStep + 1); // Cambia a la siguiente ventana
  };
  console.log("currenstep", currentStep);
  
  return (
    <div>
      {currentStep === 1 && <RequestPasswordReset onNextStep={handleNextStep} />}
      {currentStep === 2 && <VerificationCode email={email} onNextStep={handleNextStep} restablecer={true} />}
      {currentStep === 3 && <ChangePassword email={email} />}
    </div>
  );
}

export default PasswordResetFlow;
