import React, { useEffect, useState } from 'react';
import './style.css'; // Asegúrate de importar el archivo CSS
import { useStore } from '../../store';
import { useLocation } from 'react-router-dom';

const CreateReview = ({idOwner}) => {
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState(false)
  const { createReview, user } = useStore();

  const location = useLocation();

  const handleChange = (event) => {
    setReviewText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (rating === 0) {
      setError(true)
    } else {
      createReview({
        message: reviewText,
        qualification: rating,
        idUser: user.id,
        idService: location.pathname.split('/')[2],
        idOwner: idOwner
      });
      setReviewText('');
      setRating(0);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleRatingClick = (rate) => {
    setRating(rate);
  };



  return (
    <div className="create-review-container">
      <h2>Crear Reseña</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          value={reviewText}
          onChange={handleChange}
          rows="4"
          placeholder="Escribe tu reseña aquí..."
          required
        />

        <div className="rating-container">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              onClick={() => handleRatingClick(star)}
              style={{
                color: rating >= star ? 'gold' : 'gray',
              }}
            >
              ★
            </button>
          ))}
        </div>
        <div className='d-flex justify-content-center'>
          {error && <p className='error'>Por favor seleccione una calificación</p>}
        </div>

        <div className="buttons">
          <button type="submit">Enviar Reseña</button>
          <button type="button" onClick={() => { setReviewText(''); setRating(0); }}>Limpiar</button>
        </div>
      </form>
    </div>
  );
};

export default CreateReview;