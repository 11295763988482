import axios from "axios";

const getUsers = async () => {
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${URL.URL_API}/api/admin/user`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return data;
}


const adminAction = {
    getUsers,
};

export default adminAction;