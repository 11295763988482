import React from "react";
import "./styles.css"; // Asegúrate de importar el archivo CSS
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function ServicePhotos({ images }) {
  return (
    <div className="service-photos">
      <h3>Fotos del servicio</h3>
      <div className="photos-grid">
        <Carousel>
          {images &&
            images.map((image, index) => (
              <img
                key={index}
                src={`https://www.contrataexpertos.com.ar/Servicios/Imagenes/${image.url}`}
                alt={`Service ${index}`}
                className="photo-item"
              />
            ))}
        </Carousel>
      </div>
    </div>
  );
}

export default ServicePhotos;
