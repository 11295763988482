// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_input__ccpvi{
    text-align: center;
    height: 20px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,YAAY;IACZ,6BAA6B;IAC7B,aAAa;AACjB","sourcesContent":[".input{\n    text-align: center;\n    height: 20px;\n    background-color: transparent;\n    border: none;\n    border-bottom: 1px solid gray;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `style_input__ccpvi`
};
export default ___CSS_LOADER_EXPORT___;
