// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatsapp-button {
    background-color: #25D366; /* Color verde característico de WhatsApp */
    color: #FFFFFF; /* Color de texto blanco */
    border: none; /* Sin borde */
    padding: 8px 16px; /* Padding más pequeño */
    font-size: 14px; /* Tamaño de fuente más pequeño */
    cursor: pointer; /* Cambiar cursor a pointer al pasar por encima */
    border-radius: 15px; /* Bordes redondeados */
    margin-right: 10px; /* Margen a la derecha para espaciar los botones */
}

.whatsapp-button:hover {
    background-color: #128C7E; /* Color de fondo más oscuro al pasar el ratón */
}`, "",{"version":3,"sources":["webpack://./src/components/ServiceProviderDetails/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,2CAA2C;IACtE,cAAc,EAAE,0BAA0B;IAC1C,YAAY,EAAE,cAAc;IAC5B,iBAAiB,EAAE,wBAAwB;IAC3C,eAAe,EAAE,iCAAiC;IAClD,eAAe,EAAE,iDAAiD;IAClE,mBAAmB,EAAE,uBAAuB;IAC5C,kBAAkB,EAAE,kDAAkD;AAC1E;;AAEA;IACI,yBAAyB,EAAE,gDAAgD;AAC/E","sourcesContent":[".whatsapp-button {\n    background-color: #25D366; /* Color verde característico de WhatsApp */\n    color: #FFFFFF; /* Color de texto blanco */\n    border: none; /* Sin borde */\n    padding: 8px 16px; /* Padding más pequeño */\n    font-size: 14px; /* Tamaño de fuente más pequeño */\n    cursor: pointer; /* Cambiar cursor a pointer al pasar por encima */\n    border-radius: 15px; /* Bordes redondeados */\n    margin-right: 10px; /* Margen a la derecha para espaciar los botones */\n}\n\n.whatsapp-button:hover {\n    background-color: #128C7E; /* Color de fondo más oscuro al pasar el ratón */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
