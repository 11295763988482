import axios from 'axios';
import { HTTP, ROUTE, URL } from '../http';


const authLogin = async (usuario, set, get) => {
  
try{
    const result = await HTTP.POST(
      URL.URL_API + ROUTE + "/login",
      usuario
    );
    console.log("result",result)
    if (result.status === 400) {
      throw new Error(result);
    } else {
      localStorage.setItem("token", result.token);
      set({ user: result.usuario, login: true, error: null });
      return result;
    }
  }catch(error){
    throw new Error(error);
  }
}

const authLoginGoogle = async (usuario, set, get) => {
  console.log("Usuario",usuario.uid);
  const currentError = get().error;
  try {
    const result = await axios.post(
      URL.URL_API + ROUTE + `/login/google/${usuario.uid}`,
      usuario
    );
    localStorage.setItem("token", result.token);
    set({ user: result.usuario, login: true, error: null });
    return result;
  } catch (error) {
    set({ error: { ...currentError, login: { status: true, message: error.response.data.message } } })
  }
}

const authRegister = async (usuario, set, get) => {
  const currentError = get().error;
  try {
    const result = await HTTP.POST(
      URL.URL_API + ROUTE + "/register",
      usuario
    );
    if (result.status === 400) {
      throw new Error({ status: result.status, message: result.message });
    } else {
      localStorage.setItem("token", result.token);
      set({ user: result.user, login: true, error: null });
      return result;
    }
  } catch (error) {
    throw new Error(error);
  }
}

const authRegisterGoogle = async (usuario, set, get) => {
  console.log(usuario)
  const currentError = get().error;
  try {
    const result = await axios.post(
      URL.URL_API + ROUTE + "/register/google",
      usuario
    );
    localStorage.setItem("token", result.token);

    set({ user: result.usuario, login: true, error: null });
    return result;
  } catch (error) {
    set({ error: { ...currentError, register: { status: true, message: error.response.data.message } } })
  }
}

const authLogout = (navigate, set) => {
  localStorage.removeItem("token");
  set({ user: {}, login: false, showSidebar: false });
  navigate("/");
}

const authActions = {
  authLogin,
  authLoginGoogle,
  authRegister,
  authRegisterGoogle,
  authLogout
};

export default authActions;