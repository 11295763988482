
import { useStore } from '../store'


export const handleWhatsAppClick = (Owner, title, idServicio, navigate) => {
    console.log("id servicio",idServicio);
    
    const { login, whatsappClicks, incrementWhatsappClicks } = useStore.getState()
    if (!login && whatsappClicks >= 2) {
        alert("Para contactar a más expertos, por favor regístrate.");
        navigate("/register"); // Redirige al registro si es necesario
        return;
    }

    if (Owner.phone) {
        const encodedMessage = encodeURIComponent(
            `Hola, me contacto por https://ContrataExpertos.com/service/${idServicio} y necesito un presupuesto por ${title}`
        );
        const whatsappUrl = `https://api.whatsapp.com/send/?phone=${Owner.phone}&text=${encodedMessage}&type=phone_number&app_absent=0`;
        window.open(whatsappUrl, "_blank");
        incrementWhatsappClicks(); // Incrementa el contador de clics
    } else {
        alert("El número de contacto no está disponible en este servicio.");
    }
};
