import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { Table, Button, Modal, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./MisServicios.css"; // Importar el archivo CSS

import { useStore } from "../../store";

const MisServicios = () => {
  const { deleteService, userServices } = useStore();

  const navigate = useNavigate();
  const [service, setService] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDelete = (servicio) => {
    setServiceToDelete(servicio);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      deleteService(serviceToDelete.id);
      setShowDeleteModal(false);
      await fetchServices()
    } catch (error) {
      setError("Error al eliminar el servicio.");
    }
  };

  const handleEdit = (servicio) => {
    navigate(`/ABM_Servicios/EditarServicio/${servicio.id}`);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setServiceToDelete(null);
  };
  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await userServices();
      setService(response);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
    setLoading(false);
  }
  useEffect(() =>{

    fetchServices();

  },[])

  return (
    <div className="container">
      <div className="header">
        <h2>Mis Servicios</h2>
        <div className="header-buttons">
          <Link
            to="/ABM_Servicios/NuevoServicio"
            className="btn btn-primary nuevo-servicio"
          >
            Nuevo Servicio
          </Link>
          <Button className="btn btn-secondary" onClick={() => navigate("/")}>
            Ir a Home
          </Button>
        </div>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      {!error && !loading && service.length === 0 && (
        <Alert variant="info">No tienes servicios todavía.</Alert>
      )}
      {
        loading && <Alert variant="info">Cargando servicios</Alert>
      }
      {service.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Imagen</th>
              <th>Título</th>
              <th className="description-cell">Descripción</th>
              <th className="contact-cell">Número de Contacto</th>
              <th className="contact-cell">Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {service.map((servicio) => (
              <tr key={servicio.id}>
                <td>
                  <div style={{ width: "200px" }}>
                    {servicio.Images && servicio.Images.length > 0 ? (
                      <Carousel
                        showThumbs={false}
                        infiniteLoop
                        useKeyboardArrows
                      >
                        {servicio.Images.map((imagen, imgIndex) => (
                          <div key={imgIndex}>
                            <img
                              className="img-card"
                              src={`https://www.contrataexpertos.com.ar/Servicios/Imagenes/${imagen.url}`}
                              alt={`Service ${imgIndex}`}
                              lazy="loading"
                              style={{
                                width: "20%",
                                height: "auto",
                              }}
                            />
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      <img
                        className="img-card"
                        src="https://www.contrataexpertos.com/ImagenesSistema/LogoContrataExpertos.jpeg"
                        alt="Default"
                      />
                    )}
                  </div>
                </td>
                <td>{servicio.title}</td>
                <td className="description-cell">{servicio.description}</td>
                <td className="contact-cell">{servicio.contactNumber}</td>
                <td className="contact-cell">{servicio.status}</td>
                <td>
                  {servicio.status !== "Pendiente" && (
                    <Button
                      variant="primary"
                      className="button-icon"
                      onClick={() =>
                        navigate(
                          `/edit-service/${servicio.id}`,
                          {
                            state: { servicio },
                          }
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                  <Button
                    variant="danger"
                    className="button-icon"
                    onClick={() => handleDelete(servicio)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que deseas eliminar este servicio?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MisServicios;
