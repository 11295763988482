// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formulario-servicio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  padding-top: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.recomendaciones {
  margin-bottom: 20px;
}

.descripcion-textarea {
  height: 100px; /* Ajusta la altura según tus necesidades */
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-cancelar {
  flex: 1 1;
  margin-right: 10px;
}

.btn-crear {
  flex: 1 1;
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ABM_Servicios/NuevoServicio.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa,EAAE,2CAA2C;AAC5D;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,SAAO;EACP,iBAAiB;AACnB","sourcesContent":[".formulario-servicio {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  background-color: white;\n  padding: 20px;\n  padding-top: 40px;\n  border-radius: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.recomendaciones {\n  margin-bottom: 20px;\n}\n\n.descripcion-textarea {\n  height: 100px; /* Ajusta la altura según tus necesidades */\n}\n\n.button-container {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n}\n\n.btn-cancelar {\n  flex: 1;\n  margin-right: 10px;\n}\n\n.btn-crear {\n  flex: 1;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
