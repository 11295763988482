import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from '../store';

const useNavigationChange = (onNavigationChange) => {
    const { handleModal, showSidebar, authLogout, user } = useStore();

  const location = useLocation();

  useEffect(() => {
    
      handleModal(!showSidebar);

    
  }, [location]);
};

export default useNavigationChange;