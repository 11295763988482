import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

//Style
import style from "./style.module.css";
import { useStore } from "../../store";
import useNavigationChange from "../../hook/navigation";

const Sidebar = () => {
  const { handleModal, showSidebar, authLogout, user, login } = useStore();
  const [country, setCountry] = useState(null);

  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  const handleNavigate = (route) => {
    handleModal(!showSidebar);
    navigate(route, { state: { userDueño: true } });
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      handleModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get('https://ipinfo.io/json');
        const { country } = response.data;
        setCountry(country);
      } catch (error) {
        console.error('Error fetching country:', error);
      }
    };

    fetchCountry();
  }, []);
  console.log("country", country);
  
  
  return (
    <div ref={sidebarRef} className={style.sidebar}>
      {login ?
        <>
          <p onClick={() => handleNavigate("/")}>Inicio</p>
          <p onClick={() => handleNavigate("/favoritos")}>Mis favoritos</p>
          <p onClick={() => handleNavigate("/arvida")}>Seguro Accidentes Personales</p>
          {user?.idRol === "2" && (
            <p onClick={() => handleNavigate("/admin")}>Admin</p>
          )}
          <p onClick={() => handleNavigate("/ABM_Servicios/MisServicios")}>
            Mis servicios
          </p>
          <p onClick={() => handleNavigate("/ABM_Servicios/NuevoServicio")}>
            Crear servicios
          </p>
          <p onClick={() => handleNavigate(`/profile/${user.id}`)}>Mi perfil</p>
          <p onClick={() => authLogout(navigate)}>Salir</p>
        </>
        :
        <>
          <p onClick={() => handleNavigate("/login")}>Acceder</p>
          <p onClick={() => handleNavigate("/login")}>Publicar</p>
          { country === 'AR' &&  <p onClick={() => handleNavigate("/arvida")}>Seguro Accidentes Personales</p>}
          
        </>
      
      }

    </div>
  );
};

export default Sidebar;
