import axios from 'axios';
import { URL } from '../http';
const serviceGet = async (set) => {
    const { data } = await axios.get(`${URL.URL_API}/api/service`);
    set({ service: data });
}

const serviceCreate = async (set, get, service) => {
    console.log("serviceCreate",service)
    if(service.phone){
        set({user:{...get().user, phone: service.phone}})
    }

    const { data } = await axios.post(
        `${URL.URL_API}/api/service`,
        service,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
    const currentUser = get().user;
    set({ user: { ...currentUser, service: data } });
    return data;
}

const serviceDelete = async (id, set, get) => {
    await axios.delete(`${URL.URL_API}/api/service/${id}`);
    const currentUser = get().user;
    // Filtrar los servicios para excluir el eliminado
    const updatedServices = currentUser.Services.filter(
        (service) => service.id !== id
    );
    set({ user: { ...currentUser, service: updatedServices } });
}

const serviceUpdate = async (service) => {
    console.log("updateservice",service);
    

    const { data } = await axios.put(
        `${URL.URL_API}/api/service/detail/service/${service.id}`,
        service,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
    return data;
}

const serviceById = async (id) => { 
    const { data } = await axios.get(`${URL.URL_API}/api/service/${id}`);
    
    return data;
}


const serviceAction = {
    serviceGet,
    serviceDelete,
    serviceCreate,
    serviceById,
    serviceUpdate,
};

export default serviceAction;