// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctn-admin {
  padding-top: 5em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.on {
  background-color: black;
  color: white;
}

.off {
  background-color: rgb(211, 211, 211);
  color: black;
}

button:hover {
  background-color: rgb(42, 42, 42);
  color: white;
}

@media screen and (max-width: 1268px) {
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,YAAY;AACd;;AAEA;AACA","sourcesContent":[".ctn-admin {\n  padding-top: 5em;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n}\n\n.on {\n  background-color: black;\n  color: white;\n}\n\n.off {\n  background-color: rgb(211, 211, 211);\n  color: black;\n}\n\nbutton:hover {\n  background-color: rgb(42, 42, 42);\n  color: white;\n}\n\n@media screen and (max-width: 1268px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
