// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 50px;
}

.nuevo-servicio {
    margin-top: 10px;
}

.description-cell {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button-icon {
    padding: 0;
    border: none;
    background: none;
}

.button-icon:hover {
    background: none;
}



@media (max-width: 576px) {
    .description-cell,
    .contact-cell {
        display: none;
    }
}

/* MisServicios.css */
.container {
    padding: 20px;
    padding-top: 5em;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header-buttons {
    display: flex;
    gap: 100px; /* Ajusta este valor para el espaciado entre los botones */
}

.nuevo-servicio {
    margin-left: auto; 
    margin-right: 40px; 
}`, "",{"version":3,"sources":["webpack://./src/pages/ABM_Servicios/MisServicios.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;;;AAIA;IACI;;QAEI,aAAa;IACjB;AACJ;;AAEA,qBAAqB;AACrB;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,UAAU,EAAE,0DAA0D;AAC1E;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n    padding-top: 50px;\n}\n\n.nuevo-servicio {\n    margin-top: 10px;\n}\n\n.description-cell {\n    max-width: 200px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.button-icon {\n    padding: 0;\n    border: none;\n    background: none;\n}\n\n.button-icon:hover {\n    background: none;\n}\n\n\n\n@media (max-width: 576px) {\n    .description-cell,\n    .contact-cell {\n        display: none;\n    }\n}\n\n/* MisServicios.css */\n.container {\n    padding: 20px;\n    padding-top: 5em;\n}\n\n.header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n}\n\n.header-buttons {\n    display: flex;\n    gap: 100px; /* Ajusta este valor para el espaciado entre los botones */\n}\n\n.nuevo-servicio {\n    margin-left: auto; \n    margin-right: 40px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
