import React from "react";

const ArvidaEmbebido = () => {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe
        src="https://accidentespersonales.ar-vida.com/?mayconstructores"
        title="Sitio Embebido"
        style={{ width: "100%", height: "100%", border: "none" }}
      ></iframe>
    </div>
  );
};

export default ArvidaEmbebido;