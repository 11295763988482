// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --navbar-bg: rgba(255, 255, 255, 0.25);
  --navbar-shadow: rgba(31, 38, 135, 0.37);
  --navbar-border: rgba(255, 255, 255, 0.18);
  --blur-effect: blur(4px);
  --img-size: 50px;
}

.style_navbar__DNcmI {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  width: 100%;
  z-index: 99;

  background: var(--navbar-bg);
  box-shadow: 0 8px 32px 0 var(--navbar-shadow);
  backdrop-filter: var(--blur-effect);
  -webkit-backdrop-filter: var(--blur-effect);
  border-radius: 10px;
  border: 1px solid var(--navbar-border);
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.style_navbar__DNcmI:hover {
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.47);
}

.style_navbar__DNcmI > a > img {
  width: var(--img-size);
  height: var(--img-size);
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.style_navbar__DNcmI > a > img:hover {
  transform: scale(1.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/style.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,wCAAwC;EACxC,0CAA0C;EAC1C,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,WAAW;;EAEX,4BAA4B;EAC5B,6CAA6C;EAC7C,mCAAmC;EACnC,2CAA2C;EAC3C,mBAAmB;EACnB,sCAAsC;EACtC,sDAAsD;AACxD;;AAEA;EACE,qCAAqC;EACrC,gDAAgD;AAClD;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[":root {\n  --navbar-bg: rgba(255, 255, 255, 0.25);\n  --navbar-shadow: rgba(31, 38, 135, 0.37);\n  --navbar-border: rgba(255, 255, 255, 0.18);\n  --blur-effect: blur(4px);\n  --img-size: 50px;\n}\n\n.navbar {\n  position: fixed;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  text-align: center;\n  padding: 0 10px;\n  width: 100%;\n  z-index: 99;\n\n  background: var(--navbar-bg);\n  box-shadow: 0 8px 32px 0 var(--navbar-shadow);\n  backdrop-filter: var(--blur-effect);\n  -webkit-backdrop-filter: var(--blur-effect);\n  border-radius: 10px;\n  border: 1px solid var(--navbar-border);\n  transition: background 0.3s ease, box-shadow 0.3s ease;\n}\n\n.navbar:hover {\n  background: rgba(255, 255, 255, 0.35);\n  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.47);\n}\n\n.navbar > a > img {\n  width: var(--img-size);\n  height: var(--img-size);\n  border-radius: 50%;\n  transition: transform 0.3s ease;\n}\n\n.navbar > a > img:hover {\n  transform: scale(1.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `style_navbar__DNcmI`
};
export default ___CSS_LOADER_EXPORT___;
