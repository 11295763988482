import React, {useState} from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Modal.css";
const ServiceModal = ({
  isOpen,
  onClose,
  service,
  onDelete,
  handleChangeStatus,
}) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");

  if (!isOpen) return null;
  const handleReject = () => {
    setIsRejectModalOpen(true);
  };

  const handleRejectSubmit = () => {
    // Lógica para enviar el mensaje de rechazo
    console.log("Mensaje de rechazo:", rejectMessage);
    handleChangeStatus("Rechazado", rejectMessage);
    setIsRejectModalOpen(false);
    setRejectMessage("");
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-user-service">
          <div className="modal-service">
            <span className="modal-close" onClick={onClose}>
              &times;
            </span>
            <h2>{service.title}</h2>
            <p>{service.description}</p>

            {service.Images && service.Images.length > 0 && (
              <Carousel showThumbs={false} infiniteLoop useKeyboardArrows>
                {service.Images.map((image, index) => (
                  <div key={index}>
                    <img
                      className="img-service-admin"
                      src={`https://www.contrataexpertos.com.ar/Servicios/Imagenes/${image.url}`}
                      alt={`Service ${index + 1}`}
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>

          <div className="modal-user">
            <img
              className="img-user-admin"
              src={
                service.Owner.photo
                  ? `https://www.contrataexpertos.com.ar/Perfil/Usuarios/${service.Owner.photo}`
                  : "https://www.contrataexpertos.com.ar/Perfil/Usuarios/edit-profile-vector-icon.jpg"
              }
              alt="User"
            />
            <h3>
              {service.Owner.firstName} {service.Owner.lastName}
            </h3>
            <p>
              <strong>Email:</strong> {service.Owner.email}
            </p>
            <p>
              <strong>Telefono:</strong> {service.Owner.phone}
            </p>
          </div>
        </div>
        <div className="modal-buttons">
          <button
            className="apr-act"
            onClick={() => handleChangeStatus("Aprobado")}
          >
            Aprobar
          </button>
          <button onClick={handleReject}>
            Rechazar
          </button>
          <button className="danger" onClick={onDelete}>
            Eliminar
          </button>
          <button onClick={() => handleChangeStatus("Pausado")}>Pausar</button>
        </div>
      </div>
      {isRejectModalOpen && (
        <div className="modal-overlay" onClick={() => setIsRejectModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="modal-close" onClick={() => setIsRejectModalOpen(false)}>
              &times;
            </span>
            <div className="modal-body">
              <h2>Rechazar Servicio</h2>
              <textarea
                value={rejectMessage}
                onChange={(e) => setRejectMessage(e.target.value)}
                placeholder="Escribe el motivo del rechazo"
              ></textarea>
              <div className="modal-buttons">
                <button onClick={handleRejectSubmit}>Enviar</button>
                <button onClick={() => setIsRejectModalOpen(false)}>Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceModal;
