import axios from 'axios';
import {URL} from '../../http';

const adminServiceAprove = async (service, set, get) => {
    const currentService = get().service;
    const token = localStorage.getItem("token");
    try {
        await axios.put(
            URL.URL_API + "/api/admin/service/" + service.id,
            { status: "Aprobado" },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const updatedServices = currentService.map((currentService) => {
            if (currentService.id === service.id) {
                return { ...currentService, status: "Aprobado" };
            }
            return currentService;
        });
        set({ service: updatedServices });
    } catch (error) {
        console.log(error);
    }
}

const adminServiceDelete = async (id, set, get) => {
    const token = localStorage.getItem("token");
    await axios.delete(`${URL.URL_API}/api/service/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const currentService = get().service;

    const updatedServices = currentService.filter(
        (service) => service.id !== id
    );
    set({ service: updatedServices });
}

const adminServiceStatus = async (id, status, set, get) => {
    const token = localStorage.getItem("token");
    await axios.put(`${URL.URL_API}/api/admin/service/${id}`, {
      status: status,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const currentService = get().service;
    const updatedServices = currentService.map((service) => {
      if (service.id === id) {
        return { ...service, status: status };
      }
      return service;
    });
    set({ service: updatedServices });
}
const adminServiceGet   = async () => {
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${URL.URL_API}/api/admin/service`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return data;
}

const adminServiceAction = {
    adminServiceAprove,
    adminServiceDelete,
    adminServiceStatus,
    adminServiceGet
};

export default adminServiceAction;