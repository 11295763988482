// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilo general */


/* Contenedor principal */
.verification-container {
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
    font-family: 'Arial', sans-serif;

}

/* Títulos */
.verification-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.verification-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

/* Contenedor de inputs */
.input-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

/* Inputs */
.verification-input {
    width: 60px;
    height: 60px;
    font-size: 24px;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.2s;
}

.verification-input:focus {
    border-color: #007bff;
}

/* Mensaje de error */
.error-message {
    color: #d9534f;
    font-size: 14px;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Verification/style.css"],"names":[],"mappings":"AAAA,mBAAmB;;;AAGnB,yBAAyB;AACzB;IACI,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,yCAAyC;IACzC,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,gCAAgC;;AAEpC;;AAEA,YAAY;AACZ;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;;AAEA,yBAAyB;AACzB;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,mBAAmB;AACvB;;AAEA,WAAW;AACX;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,qBAAqB;AACzB;;AAEA,qBAAqB;AACrB;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["/* Estilo general */\n\n\n/* Contenedor principal */\n.verification-container {\n    background-color: #fff;\n    padding: 30px 40px;\n    border-radius: 10px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    width: 100%;\n    max-width: 400px;\n    font-family: 'Arial', sans-serif;\n\n}\n\n/* Títulos */\n.verification-title {\n    font-size: 24px;\n    font-weight: bold;\n    margin-bottom: 10px;\n}\n\n.verification-subtitle {\n    font-size: 16px;\n    color: #666;\n    margin-bottom: 20px;\n}\n\n/* Contenedor de inputs */\n.input-container {\n    display: flex;\n    justify-content: space-between;\n    gap: 10px;\n    margin-bottom: 20px;\n}\n\n/* Inputs */\n.verification-input {\n    width: 60px;\n    height: 60px;\n    font-size: 24px;\n    text-align: center;\n    border: 2px solid #ddd;\n    border-radius: 8px;\n    outline: none;\n    transition: border-color 0.2s;\n}\n\n.verification-input:focus {\n    border-color: #007bff;\n}\n\n/* Mensaje de error */\n.error-message {\n    color: #d9534f;\n    font-size: 14px;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
