// AnimatedRoutes.jsx
import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const AnimatedRoutes = ({ routes }) => {
  const location = useLocation(); // Obtén la ubicación actual

  return (
    <Routes location={location} key={location.key}>
      {routes.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={
            <motion.div
              initial={{ opacity: 0, y: 20 }} // Estado inicial
              animate={{ opacity: 1, y: 0 }} // Estado animado
              exit={{ opacity: 0, y: -20 }} // Estado de salida
              transition={{ duration: 0.3 }} // Duración de la transición
            >
              {element}
            </motion.div>
          }
        />
      ))}
    </Routes>
  );
};

export default AnimatedRoutes;
