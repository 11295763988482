import React from 'react';

function UserReviews({ reviews }) {
    // Convertir las calificaciones de string a number y calcular el promedio
    const totalReviews = reviews ? reviews.length : 0;
    const averageRating = totalReviews > 0 
        ? (reviews.reduce((sum, review) => sum + parseFloat(review.qualification), 0) / totalReviews).toFixed(1) 
        : 0;

    // Calcular el número de estrellas amarillas y grises
    const yellowStars = Math.round(averageRating);
    const grayStars = 5 - yellowStars;

    return (
        <div className="user-reviews">
            <h3>Reseña de los usuarios</h3>
            <div className="rating-summary">
                <div className="star-rating">
                    {Array.from({ length: yellowStars }, (_, i) => (
                        <span key={i} style={{ color: 'yellow' }}>⭐</span>
                    ))}

                </div>
                <p><span>Promedio: {parseInt(averageRating)}</span></p>
                <span>Total de reseñas: {totalReviews}</span>
            </div>

            {
                totalReviews > 0 ? reviews.map((review, index) => (
                    <div className="review" key={index}>
                        <h4>{review.title}</h4>
                        <p>
                            "{review.message}"
                        </p>

                        <div className="star-rating">
                            {Array.from({ length: parseInt(review.qualification) }, (_, i) => (
                                <span key={i} style={{ color: 'yellow' }}>⭐</span>
                            ))}
                        </div>
                    </div>
                ))
                    :
                    <div className="review" >
                        <p>Sin reseñas aun</p>
                    </div>
            }

        </div>
    );
}

export default UserReviews;