// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rejection-message {
    border: 1px solid #ff4d4f; /* Rojo para indicar error */
    background-color: #fff1f0; /* Fondo suave de advertencia */
    color: #a8071a; /* Color del texto */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    display: flex;
    
    flex-direction: column;
    position: absolute;
    bottom: 0;
  }
  
  .rejection-message h2 {
    margin: 0 10px 0 0;
    font-size: 18px;
  }
  
  .rejection-message p {
    margin: 0;
    font-size: 16px;
  }
  
  .rejection-message::before {
    content: "⚠️"; /* Icono de advertencia */
    font-size: 24px;
    margin-right: 10px;
  }
`, "",{"version":3,"sources":["webpack://./src/pages/EditService/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,4BAA4B;IACvD,yBAAyB,EAAE,+BAA+B;IAC1D,cAAc,EAAE,oBAAoB;IACpC,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,8BAA8B;IAC9B,aAAa;;IAEb,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,SAAS;IACT,eAAe;EACjB;;EAEA;IACE,aAAa,EAAE,yBAAyB;IACxC,eAAe;IACf,kBAAkB;EACpB","sourcesContent":[".rejection-message {\n    border: 1px solid #ff4d4f; /* Rojo para indicar error */\n    background-color: #fff1f0; /* Fondo suave de advertencia */\n    color: #a8071a; /* Color del texto */\n    padding: 20px;\n    margin-bottom: 20px;\n    border-radius: 5px;\n    font-family: Arial, sans-serif;\n    display: flex;\n    \n    flex-direction: column;\n    position: absolute;\n    bottom: 0;\n  }\n  \n  .rejection-message h2 {\n    margin: 0 10px 0 0;\n    font-size: 18px;\n  }\n  \n  .rejection-message p {\n    margin: 0;\n    font-size: 16px;\n  }\n  \n  .rejection-message::before {\n    content: \"⚠️\"; /* Icono de advertencia */\n    font-size: 24px;\n    margin-right: 10px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
