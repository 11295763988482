// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-bg-color {
  background-color: #e2e6f5; 
}






 `, "",{"version":3,"sources":["webpack://./src/pages/RegisterReferrals/RegisterReferralsScreen.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[".custom-bg-color {\n  background-color: #e2e6f5; \n}\n\n\n\n\n\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
