import React, { useState, useEffect } from "react";
import style from './style.module.css';

const InputComponent = ({ placeholder, set, value }) => {
  const [localValue, setLocalValue] = useState(value); 

  useEffect(() => {
    const timer = setTimeout(() => {
      set(localValue); // Actualiza el valor solo después de 3 segundos
    }, 2000);

    return () => clearTimeout(timer); // Limpia el temporizador en cada cambio
  }, [localValue, set]);

  return (
    <input
      value={localValue}
      className={style.input}
      placeholder={placeholder}
      onChange={(e) => setLocalValue(e.target.value)} // Actualiza el estado local al escribir
    />
  );
};

export default InputComponent;
