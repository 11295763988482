import React from "react";
import "./Modal.css"; // Asegúrate de crear este archivo CSS

const UserModal = ({ isOpen, onClose, user }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="modal-close" onClick={onClose}>
          &times;
        </span>
        <h2>{user.firstName} {user.lastName}</h2>
        <img
              className="img-photo-admin"
              src={
                user.photo
                  ? `https://www.contrataexpertos.com.ar/Perfil/Usuarios/${user.photo}`
                  : "https://www.contrataexpertos.com.ar/Perfil/Usuarios/edit-profile-vector-icon.jpg"
              }
              alt="User"
            />
        <p>Email: {user.email}</p>
        <p>Rol: {user.idRol === '2' ? 'Admin' : 'Experto'}</p>
        <div className="modal-buttons">
          <button onClick={() => console.log("Aprobar", user)}>Aprobar</button>
          <button onClick={() => console.log("Rechazar", user)}>Rechazar</button>
          <button onClick={() => console.log("Eliminar", user)}>Eliminar</button>
          <button onClick={() => console.log("Pausar", user)}>Pausar</button>
        </div>
      </div>
    </div>
  );
};

export default UserModal;