import React, { useState, useEffect } from "react";
import UserListAdmin from "../../components/Admin/UserList";
import ServiceListAdmin from "../../components/Admin/ServiceList";

import { useStore } from "../../store";
import "./style.css";

const AdminPage = () => {
  const { statusFilter, getUsers, adminServiceGet } = useStore();

  const [users, setUsers] = useState([]);
  const [flag, setFlag] = useState(false);
  const [service, setService] = useState([]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setUsers(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() =>{
    const fetchServices = async () => {
      try {
        const response = await adminServiceGet();
        setService(response);
        console.log(response)
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };
    fetchServices();
  },[])

  const filteredServices = service.filter(service =>
    statusFilter === 'all' || service.status === statusFilter
  );

  return (
    <div className="ctn-admin">

      <div className="d-flex pt-5">
        <button onClick={() => setFlag(!flag)} className={flag ? "on" : "off"}>
          Usuarios
        </button>
        <button onClick={() => setFlag(!flag)} className={!flag ? "on" : "off"}>
          Servicios
        </button>
      </div>
      {flag ? (
        <UserListAdmin users={users} />
      ) : (
        <ServiceListAdmin services={filteredServices} />
      )}
    </div>
  );
};

export default AdminPage;
