// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    margin-bottom: 10px;
    text-align: left;
  }
  
  .input {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  
  .message {
    margin-top: 10px;
    color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/restablecimientoDeContraseña/styles.css"],"names":[],"mappings":";;EAEE;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,UAAU;EACZ","sourcesContent":["\n  \n  .title {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .label {\n    margin-bottom: 10px;\n    text-align: left;\n  }\n  \n  .input {\n    padding: 10px;\n    font-size: 16px;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n    margin-bottom: 15px;\n  }\n  \n  .button {\n    padding: 10px 20px;\n    font-size: 16px;\n    background-color: #4caf50;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .button:hover {\n    background-color: #45a049;\n  }\n  \n  .message {\n    margin-top: 10px;\n    color: red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
