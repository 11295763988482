import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";

import ServiceModal from "./ServiceModal";

import { useStore } from "../../store";

import "./style.css";
import Menu from "../Menu/index.jsx";

const ServiceListAdmin = ({ services }) => {
  const { deleteServiceAdmin, aproveService, putService } = useStore();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedService, setSelectedService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const servicesPerPage = 3;

  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = services.slice(indexOfFirstService, indexOfLastService);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openModal = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setIsModalOpen(false);
  };

  const handleChangeStatus = (status, message) => {
    putService(selectedService.id, status, message);
    closeModal();
  };

  const handleApprove = () => {
    aproveService(selectedService.id);
    closeModal();
  };

  const handleReject = () => {
    closeModal();
  };

  const handleDelete = () => {
    deleteServiceAdmin(selectedService.id);
    closeModal();
  };

  const handlePause = () => {
    closeModal();
  };
  
  return (
    <>
      <h1 className="d-flex justify-content-center pt-4">Lista de Servicios</h1>
      <div className="ctn-card-admin">
        <Menu />
        <div className="ctn-card-admin">
          {currentServices.map((service, index) => (
            <div key={index} className="card-admin">
              <div style={{ height: "400px" }}>
                <h3>{service.title}</h3>
                <p className="description">{service.description}</p>
                <p>Estado: {service.status}</p>
                {service.Images && service.Images.length > 0 && (
                  <Carousel showThumbs={false} infiniteLoop useKeyboardArrows>
                    {service.Images.map((image, imgIndex) => (
                      <div key={imgIndex}>
                        <img
                          className="img-card"
                          src={`https://www.contrataexpertos.com.ar/Servicios/Imagenes/${image.url}`}
                          alt={`Service ${index + 1}`}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
              <button onClick={() => openModal(service)}>Ver detalles</button>
            </div>
          ))}
        </div>
        <div className="pagination">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastService >= services.length}
          >
            Siguiente
          </button>
        </div>
        {selectedService && (
          <ServiceModal
            isOpen={isModalOpen}
            onClose={closeModal}
            service={selectedService}
            onApprove={handleApprove}
            onReject={handleReject}
            onDelete={handleDelete}
            onPause={handlePause}
            handleChangeStatus={handleChangeStatus}
            
          />
        )}
      </div>
    </>
  );
};

export default ServiceListAdmin;