import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import WarningModal from "../AdvertenciaModal/WarningModal"; // Importa el modal


import { useStore } from "../../store";
import "./style.css";
import { handleWhatsAppClick } from "../../utils/service";

import { useQueryClient } from "@tanstack/react-query";
import { getService } from "../../api/service/get-service";

const ServiceCard = ({ service }) => {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const {incrementWhatsappClicks, whatsappClicks, login, user, addFavorite, services, postCondicionesYPrivacidad} = useStore()
  const { title, description, rating, id, Owner} = service;

  const [isFavorite, setIsFavorite] = useState(service.isFavorite);

  const totalReviews = service.Reviews ? service.Reviews.length : 0;
  
  const averageRating = totalReviews > 0 
      ? (service.Reviews.reduce((sum, review) => sum + parseFloat(review.qualification), 0) / totalReviews).toFixed(1) 
      : 0;


  // Calcular el número de estrellas amarillas y grises
  const yellowStars = Math.round(averageRating);
  console.log("yellowStars", yellowStars);
  console.log("user", user);
  

  const queryClient = useQueryClient();
  const renderStars = () => {
    return Array(5)
      .fill(0)
      .map((_, i) => (
        <span key={i} style={{ color: i < yellowStars ? "#ffd700" : "#ccc" }}>
          &#9733;
        </span>
      ));
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const prefectData = () => {
    queryClient.prefetchQuery({
      queryKey: ['issues', service.id],
      queryFn: () => getService(service.id),
      staleTime: 1000 * 60 * 60 * 24,
    });

  }

  
  const handleAddFavorite = () => {
    setIsFavorite(!isFavorite);
    console.log('location', service.id);
    addFavorite(service.id, service.isFavorite);
}

  const menuprincipal = () => {
    navigate('/login');
  }
  console.log("user.conditions", user.conditions);
  
  const handleWhatsApp = () => {
    if (user.conditions) {
      setShowModal(true); // Muestra el modal
    } else {
      handleWhatsAppClick(service.Owner, service.title, service.id, navigate); // Redirige a WhatsApp
    }
  };

  const handleAccept = () => {
    postCondicionesYPrivacidad(user.id)
    setShowModal(false); // Oculta el modal
    handleWhatsAppClick(service.Owner, service.title, navigate); // Redirige a WhatsApp
  };

  const handleReject = () => {
    setShowModal(false); // Cierra el modal
  };
  
  console.log("service prueba", service);
  
  return (
    <div className="card mb-4">
      <div className="img-container">
        {service.Images && service.Images.length > 0 && (
          <Carousel
            showThumbs={false}
            infiniteLoop
            useKeyboardArrows
            showIndicators
            showArrows={true}
            showStatus={true}
            renderArrowNext={(clickHandler, hasNext) =>
              hasNext && (
                <button
                  type="button"
                  onClick={clickHandler}
                  title="Siguiente"
                  className="control-arrow control-next"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                />
              )
            }
            renderArrowPrev={(clickHandler, hasPrev) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={clickHandler}
                  title="Anterior"
                  className="control-arrow control-prev"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                />
              )
            }
          >
            {service.Images.map((image, index) => (
              <div key={index}>
                <img
                  className="img-service-admin"
                  src={`https://www.contrataexpertos.com.ar/Servicios/Imagenes/${image.url}`}
                  alt={`Service ${index + 1}`}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
      <div className="card-body">
        <h5 className="card-title">{truncateText(title, 40)}</h5>
        <p className="card-text card-description">
          {truncateText(description, 210)}
        </p>
        <div className="star-rating">{renderStars()} ({averageRating})  {service.price > 0 ? <span className="price-tag">${service.price}</span> : ""}</div>
        <div className="button-container">
          <button
            onClick={handleWhatsApp}
            className="whatsapp-button"
            aria-label="Contactar por WhatsApp"
          >
            <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: "5px" }} />
            WhatsApp
          </button>
          <button
            onClick={() => navigate(`/service/${id}`, { state: { isFavorite } })}
            className="details-button"
            aria-label="Ver detalles del servicio"
            onMouseEnter={() => prefectData()}
          >
            Detalles
          </button>
          <button onClick={JSON.stringify(user) == '{}' ? menuprincipal : handleAddFavorite}       style={{
        background: "none",
        border: "none",
        cursor: "pointer",
        fontSize: "2rem",
      }}>

              {isFavorite ? (
            <span style={{ color: "red" }}>❤️</span> // Corazón rojo
          ) : (
            <span style={{ color: "gray" }}>🤍</span> // Corazón blanco (gris)
          )}
          </button>
        </div>
        <WarningModal
          isOpen={showModal}
          onAccept={handleAccept}
          onReject={handleReject}        />
      </div>
    </div>
  );
};

export default ServiceCard;
