// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerHome {

}

.nav {
  grid-area: nav;
  width: 10%;
  color: white;
  padding: 20px;
  position: fixed;
  height: 100%;
  overflow-y: auto; /* Permite que el nav sea desplazable si el contenido es mayor que la pantalla */
  color: black;
  padding-top: 5em;
}

.content {
  /*quiero un grid que tenga tres columnas */
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 20px;
  padding-top: 5em;
}

.containerButton{
  display: flex;
  justify-content: space-around;
  
}

::-webkit-scrollbar {
    display: none;;
    padding-top: 5em; 
  width: 10px; /* Ancho del scrollbar */
}

::-webkit-scrollbar-track {
  /* Color de fondo del track del scrollbar */
}

::-webkit-scrollbar-thumb {
  /* background-color: black; /* Color del thumb del scrollbar */
  border-radius: 10px; /* Redondear el thumb */
  border: 3px solid black; /* Añadir un borde alrededor del thumb */
}

/* Responsive design */
@media (max-width: 800px) {
  .content {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "nav"
      "content";
  }

  .nav {
    position: relative;
    width: auto;
    height: auto;
    margin-bottom: 10px;
  }


}

`, "",{"version":3,"sources":["webpack://./src/pages/userFavorites/style.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;EACE,cAAc;EACd,UAAU;EACV,YAAY;EACZ,aAAa;EACb,eAAe;EACf,YAAY;EACZ,gBAAgB,EAAE,gFAAgF;EAClG,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,aAAa;EACb,kCAAkC;EAClC,cAAc;EACd,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,6BAA6B;;AAE/B;;AAEA;IACI,aAAa;IACb,gBAAgB;EAClB,WAAW,EAAE,wBAAwB;AACvC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,8DAA8D;EAC9D,mBAAmB,EAAE,uBAAuB;EAC5C,uBAAuB,EAAE,wCAAwC;AACnE;;AAEA,sBAAsB;AACtB;EACE;IACE,0BAA0B;IAC1B,4BAA4B;IAC5B;;eAEW;EACb;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;;;AAGF","sourcesContent":[".containerHome {\n\n}\n\n.nav {\n  grid-area: nav;\n  width: 10%;\n  color: white;\n  padding: 20px;\n  position: fixed;\n  height: 100%;\n  overflow-y: auto; /* Permite que el nav sea desplazable si el contenido es mayor que la pantalla */\n  color: black;\n  padding-top: 5em;\n}\n\n.content {\n  /*quiero un grid que tenga tres columnas */\n  grid-area: content;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  grid-gap: 10px;\n  padding: 20px;\n  padding-top: 5em;\n}\n\n.containerButton{\n  display: flex;\n  justify-content: space-around;\n  \n}\n\n::-webkit-scrollbar {\n    display: none;;\n    padding-top: 5em; \n  width: 10px; /* Ancho del scrollbar */\n}\n\n::-webkit-scrollbar-track {\n  /* Color de fondo del track del scrollbar */\n}\n\n::-webkit-scrollbar-thumb {\n  /* background-color: black; /* Color del thumb del scrollbar */\n  border-radius: 10px; /* Redondear el thumb */\n  border: 3px solid black; /* Añadir un borde alrededor del thumb */\n}\n\n/* Responsive design */\n@media (max-width: 800px) {\n  .content {\n    grid-template-columns: 1fr;\n    grid-template-rows: auto 1fr;\n    grid-template-areas:\n      \"nav\"\n      \"content\";\n  }\n\n  .nav {\n    position: relative;\n    width: auto;\n    height: auto;\n    margin-bottom: 10px;\n  }\n\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
