// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Skeleton.css */
.skeleton {
    background-color: #e0e0e0; /* Color de fondo del esqueleto */
    border-radius: 4px; /* Bordes redondeados */
    margin: 10px 0; /* Espaciado */
  }`, "",{"version":3,"sources":["webpack://./src/components/skeleton/Skeleton.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,yBAAyB,EAAE,iCAAiC;IAC5D,kBAAkB,EAAE,uBAAuB;IAC3C,cAAc,EAAE,cAAc;EAChC","sourcesContent":["/* Skeleton.css */\n.skeleton {\n    background-color: #e0e0e0; /* Color de fondo del esqueleto */\n    border-radius: 4px; /* Bordes redondeados */\n    margin: 10px 0; /* Espaciado */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
