import React from "react";
import "./style.css";
import { useStore } from "../../store";

const EditProfile = () => {
  const { user, updateProfile } = useStore();

  // Estado para la imagen de perfil, utilizando la imagen del usuario o una imagen predeterminada si no tiene
  const [profileImage, setProfileImage] = React.useState(
    user.photo
      ? `https://www.contrataexpertos.com.ar/Perfil/Usuarios/${user.photo}`
      : "https://www.contrataexpertos.com.ar/Perfil/Usuarios/edit-profile-vector-icon.jpg"
  );

  const [formData, setFormData] = React.useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    dni: user.dni,
    country: user.country,
    province: user.province,
    town: user.town,
    zipCode: user.zipCode,
    street: user.street,
    streetNumber: user.streetNumber,
    apartment: user.apartment,
    photo: user.photo,
    description: user.description,
  });
  
  const [errors, setErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const countryPhoneRules = {
    Argentina: { prefix: "+54", length: 11 },
    Bolivia: { prefix: "+591", length: 8 },
    Chile: { prefix: "+56", length: 9 },
    Colombia: { prefix: "+57", length: 10 },
    "Costa Rica": { prefix: "+506", length: 8 },
    Cuba: { prefix: "+53", length: 8 },
    Ecuador: { prefix: "+593", length: 9 },
    "El Salvador": { prefix: "+503", length: 8 },
    Guatemala: { prefix: "+502", length: 8 },
    Honduras: { prefix: "+504", length: 8 },
    México: { prefix: "+52", length: 10 },
    Nicaragua: { prefix: "+505", length: 8 },
    Panamá: { prefix: "+507", length: 8 },
    Paraguay: { prefix: "+595", length: 9 },
    Perú: { prefix: "+51", length: 9 },
    "República Dominicana": { prefix: "+1", length: 10 },
    Uruguay: { prefix: "+598", length: 8 },
    Venezuela: { prefix: "+58", length: 10 },
    España: { prefix: "+34", length: 9 },
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Actualiza el estado con la nueva imagen seleccionada
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);  // Muestra la nueva imagen
        setFormData({ ...formData, photo: file });  // Actualiza el formulario con el nuevo archivo
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "Primer nombre es requerido";
    if (!formData.lastName) newErrors.lastName = "Apellido es requerido";
    if (!formData.email) {
      newErrors.email = "Correo electrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Correo electrónico no es válido";
    }

      // Validación del número de teléfono
  if (!formData.phone) {
    newErrors.phone = "Número de teléfono es requerido";
  } else {
    const countryRule = countryPhoneRules[formData.country];
    if (countryRule) {
      const phonePattern = new RegExp(`^\\${countryRule.prefix}\\d{${countryRule.length}}$`);
      if (!phonePattern.test(formData.phone)) {
        newErrors.phone = `El número debe comenzar con "${countryRule.prefix}" y tener ${countryRule.length} dígitos después del prefijo.`;
      }
    }
  }
    //if (!formData.phone) {
    //  newErrors.phone = "Número de teléfono es requerido";
    //} //else if (!/^\+549\d{10}$/.test(formData.phone)) {
    //  newErrors.phone =
    //    "Número de teléfono debe tener el formato +549 seguido de 10 dígitos, con una longitud total de 13 caracteres";
    //}
    if (!formData.dni) {
      newErrors.dni = "DNI es requerido";
    } else if (!/^\d+$/.test(formData.dni)) {
      newErrors.dni = "DNI debe contener solo números";
    }
    if (!formData.zipCode) {
      newErrors.zipCode = "Código Postal es requerido";
    } else if (!/^\d+$/.test(formData.zipCode)) {
      newErrors.zipCode = "Código Postal debe contener solo números";
    }
    if (!formData.street) newErrors.street = "Calle es requerida";
    if (!formData.streetNumber) {
      newErrors.streetNumber = "Número de calle es requerido";
    } else if (!/^\d+$/.test(formData.streetNumber)) {
      newErrors.streetNumber = "Número de calle debe contener solo números";
    }
    if (!formData.country) newErrors.country = "País es requerido";
    return newErrors;
  };

  const sumbit = () => {
    setIsLoading(true);
    setTimeout(async () => {
      await handleSubmit();
    }, 2000);
  };

  const handleSubmit = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
    } else {
      await updateProfile(formData);

      alert("Perfil actualizado correctamente");
      setErrors({});
      setIsLoading(false);
    }
  };

  return (
    <div className="edit-profile-container">
      <div className="edit-profile-form">
        <div className="profile-image-container">
          <img
            className="profile-image"
            src={profileImage}
            alt="Profile"
          />
          <label htmlFor="imageUpload" className="image-upload-label">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              className="bi bi-camera"
              viewBox="0 0 16 16"
            >
              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
            </svg>

            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              onChange={handleImageChange}
              disabled={isLoading}
            />
          </label>
        </div>
        <div className="form-group">
          <label>País</label>
          <input
            type="text"
            name="country"
            placeholder="País"
            value={formData.country}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.country && <p className="error">{errors.country}</p>}
        </div>
        <div className="form-group">
          <label>Nombre</label>
          <input
            type="text"
            name="firstName"
            placeholder="Nombre"
            value={formData.firstName}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.firstName && <p className="error">{errors.firstName}</p>}
        </div>
        <div className="form-group">
          <label>Apellido</label>
          <input
            type="text"
            name="lastName"
            placeholder="Apellido"
            value={formData.lastName}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.lastName && <p className="error">{errors.lastName}</p>}
        </div>
        <div className="form-group">
          <label>Correo Electrónico</label>
          <input
            type="email"
            name="email"
            placeholder="Correo Electrónico"
            value={formData.email}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label>Número de Teléfono</label>
          <input
            type="tel"
            name="phone"
            placeholder="Número de Teléfono"
            value={formData.phone}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
        </div>
        <div className="form-group">
          <label>DNI</label>
          <input
            type="text"
            name="dni"
            placeholder="DNI"
            value={formData.dni}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.dni && <p className="error">{errors.dni}</p>}
        </div>

        <div className="form-group">
          <label>Provincia</label>
          <input
            type="text"
            name="province"
            placeholder="Provincia"
            value={formData.province}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.province && <p className="error">{errors.province}</p>}
        </div>
        <div className="form-group">
          <label>Ciudad</label>
          <input
            type="text"
            name="town"
            placeholder="Ciudad"
            value={formData.town}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.town && <p className="error">{errors.town}</p>}
        </div>
        <div className="form-group">
          <label>Codigo Postal</label>
          <input
            type="text"
            name="zipCode"
            placeholder="Codigo postal"
            value={formData.zipCode}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.zipCode && <p className="error">{errors.zipCode}</p>}
        </div>
        <div className="form-group">
          <label>Calle</label>
          <input
            type="text"
            name="street"
            placeholder="Calle"
            value={formData.street}
            pattern="[A-Za-z\s]+"
            inputMode="text"
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.street && <p className="error">{errors.street}</p>}
        </div>
        <div className="form-group">
          <label>Numero de Calle</label>
          <input
            type="text"
            name="streetNumber"
            placeholder="Numero de calle"
            value={formData.streetNumber}
            pattern="\d+"
            inputMode="numeric"
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.streetNumber && (
            <p className="error">{errors.streetNumber}</p>
          )}
        </div>
        <div className="form-group">
          <label>Descripción</label>
          <textarea
            type="text"
            name="description"
            placeholder="Descripción del usuario"
            value={formData.description}
            inputMode="text"
            onChange={handleInputChange}
            disabled={isLoading}
          />
          {errors.description && (
            <p className="error">{errors.description}</p>
          )}
        </div>
        <button
          disabled={isLoading}
          className="save-profile-btn"
          onClick={sumbit}
        >
          {isLoading ? <span className="spinner"></span> : "Actualizar perfil"}
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
