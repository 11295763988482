import React from 'react';
import { useStore } from '../../store';

const Menu = () => {
  const statusFilter = useStore((state) => state.statusFilter);
  const setStatusFilter = useStore((state) => state.setStatusFilter);

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  return (
    <div className='d-flex '>

      <select className='form-select' value={statusFilter} onChange={handleStatusChange}>
        <option value='Pendiente'>Pendiente</option>
        <option value="Aprobado">Aprobado</option>
        <option value="Rechazado">Rechazado</option>
        <option value="all">Todos</option>
      </select>
      {/*       <button className='filter-button m-2 w-100'>Fecha de subida</button>*/}
    </div>
  );
};

export default Menu;