import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import UserReviews from "../../components/UserReviews";
import ServicePhotos from "../../components/ServicePhotos";
import ServiceProviderDetails from "../../components/ServiceProviderDetails";

import { useStore } from "../../store";

import "./style.css";
import CreateReview from "../../components/CreateReview";
import Skeleton from "../../components/skeleton";

const Service = () => {
  const { getServiceById, user, login } = useStore();

  const navigate = useNavigate();

  const [service, setService] = useState(null);
  const [error, setError] = useState("");
  const [isFetching, setIsFetching] = useState(true); // Estado de carga

  const location = useLocation();

  useEffect(() => {
    const fetchService = async () => {
      try {
        const data = await getServiceById(location.pathname.split("/")[2]);

        if (data) {
          setService(data.service);
        }
      } catch (error) {
        setError(`Error al cargar el servicio: ${error.message}`);
      } finally {
        setIsFetching(false); // Finaliza la carga
      }
    };

    fetchService();
  }, [getServiceById, location.pathname]);

  // Mostrar error si hay
  if (error) return <div>{error}</div>;

  return (
    <div className="container">
      {isFetching ? (
        <>
          <Skeleton width="100%" height="50px" /> {/* Esqueleto para el título */}
          <Skeleton width="100%" height="200px" /> {/* Esqueleto para el servicio */}
          <Skeleton width="100%" height="300px" /> {/* Esqueleto para fotos */}
          <Skeleton width="100%" height="100px" /> {/* Esqueleto para reseñas */}
        </>
      ) : (
        service && (
          <>
            {service.Owner.id === user?.id && (
              <button onClick={() => navigate(`/edit-service/${service.id}`)}>
                Editar mi servicio
              </button>
            )}
            <ServiceProviderDetails
              title={service.title}
              description={service.description}
              reviews={service?.Reviews ? service.Reviews : []}
              Owner={service.Owner}
            />
            <ServicePhotos images={service.Images} />
            <UserReviews reviews={service?.Reviews ? service?.Reviews : []} />
            {login && user.id !== service.Owner.id && (
              <CreateReview idOwner={service.idUser} />
            )}
          </>
        )
      )}
    </div>
  );
};

export default Service;
