// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-photos {
  padding: 1rem;
  width: 100%;
  
}

.photos-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.photo-item {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1 / 1; /* Mantener una relación de aspecto cuadrada */
  direction: ltr; /* Para que el contenido de la imagen no se invierta */
}`, "",{"version":3,"sources":["webpack://./src/components/ServicePhotos/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;;AAEb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB,EAAE,8CAA8C;EACnE,cAAc,EAAE,sDAAsD;AACxE","sourcesContent":[".service-photos {\n  padding: 1rem;\n  width: 100%;\n  \n}\n\n.photos-grid {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.photo-item {\n  width: 100%;\n  height: auto;\n  object-fit: cover;\n  aspect-ratio: 1 / 1; /* Mantener una relación de aspecto cuadrada */\n  direction: ltr; /* Para que el contenido de la imagen no se invierta */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
