import React from 'react';
import { useStore } from '../../store';
import useIsFavorite from '../../hook/favorite';
import { handleWhatsAppClick } from '../../utils/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from "react-router-dom";
import './style.css';
function ServiceProviderDetails({ title, description, reviews, Owner }) {

    const { addFavorite, user } = useStore();
    const serviceId = window.location.href.split("/").pop();
    const navigate = useNavigate();
    const isFavorite = useIsFavorite(serviceId);
    const totalReviews = reviews ? reviews.length : 0;
    const handleAddFavorite = () => {
        //la location es asi http://localhost:3000/service/1 necesito solo el utlimo numero
        const location = window.location.href.split("/").pop();

        addFavorite(location);
    }
    const averageRating = totalReviews > 0
        ? (reviews.reduce((sum, review) => sum + parseFloat(review.qualification), 0) / totalReviews).toFixed(1)
        : 0;
    const yellowStars = Math.round(averageRating);

    return (
        <div className="service-provider-details">
            <h1>{title}</h1>
            <p>{description}</p>
            <div className="d-flex flex-row justify-content-between">
                <div className="rating">
                    {Array.from({ length: yellowStars }, (_, i) => (
                        <span key={i} style={{ color: 'yellow' }}>⭐</span>
                    ))}
                    <span>Total de reseñas: {reviews ? reviews.length : 0}</span>
                </div>

                <div className="actions">

                    <button
                        onClick={() => handleWhatsAppClick(Owner, title, navigate)}
                        className="whatsapp-button"
                        aria-label="Contactar por WhatsApp"
                    >
                        <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: "5px" }} />
                        WhatsApp
                    </button>
                    {/* <button onClick={handleAddFavorite}>{isFavorite ? 'Favorito' : 'Agregar a Favoritos'}</button> */}
                </div>
            </div>
        </div>
    );
}

export default ServiceProviderDetails;