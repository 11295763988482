import React, { useState, useRef } from 'react';
import axios from 'axios';
import './style.css'; // Importa el archivo CSS
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from "../../store";



const VerificationCode = ({ email, onNextStep, restablecer }) => {
    const { authRegister } = useStore();
    const navigate = useNavigate();
    
    const [code, setCode] = useState(['', '', '', '']);
    const [errorMessage, setErrorMessage] = useState('');
    const inputsRef = useRef([]);
    const location = useLocation();
    const dataUser = location.state; // Aquí obtenemos el objeto pasado
  

    const handleChange = (value, index) => {
        if (!/^\d$/.test(value)) return;

        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value && index < 3) {
            inputsRef.current[index + 1].focus();
        }

        if (newCode.every((digit) => digit !== '')) {
            handleSubmit(newCode.join(''));
        }
    };

    const handleSubmit = async (completeCode) => {
        console.log(completeCode);
        console.log(dataUser);
        console.log(restablecer);
        
        if (restablecer) {
            console.log("restablecimientodecontraseña");
            
            try {
                const response = await axios.post('http://localhost:4041/api/auth/verify-code', { email, completeCode });
                alert(response.data.message);
                if (response.data.success) onNextStep(); // Avanzar a la siguiente ventana
              } catch (error) {
                setErrorMessage('El código es incorrecto. Inténtalo de nuevo.');
              }
        } else {
            console.log("verificacion para registrarse");
            
            if (dataUser.code == completeCode) {
                dataUser.verificado = true; 
                await authRegister(dataUser)
                alert('Correo verificado con éxito');
                navigate("/")
                
            } else {
                setErrorMessage('Código incorrecto. Inténtalo nuevamente.');
                setCode(['', '', '', '']);
                inputsRef.current[0].focus();
    
            }
        }
        
    };

    return (
        <div    style={{display: 'flex', justifyContent: 'center', alignItems: 'center',height: '100vh',}}>
        <div className="verification-container">
            <h2 className="verification-title">Verifica tu correo electrónico</h2>
            <p className="verification-subtitle">Introduce el código de 4 dígitos que enviamos a tu correo.</p>
            <div className="input-container">
                {code.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        ref={(el) => (inputsRef.current[index] = el)}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => {
                            if (e.key === 'Backspace' && !code[index] && index > 0) {
                                inputsRef.current[index - 1].focus();
                            }
                        }}
                        className="verification-input"
                    />
                ))}
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
        </div>
    );
};

export default VerificationCode;
